<template>
	<layout.index :code_data="code_data"></layout.index>
</template>
<script>


export default {
	data() {
		return {
			id: this.$route.params.name,
			code_data: {}
		}
	},
	created: function() {
		//console.log(`the component is now created.`);
		// const d = this.loadData();
		//console.log(`the component is now created.3`);
	},
	mounted: function() {
		//console.log(`the component is now mounted.`);
	},
	computed: {

	},
	watch: {
		['$route.params.name']: {
			handler(newVal, oldVal) {
				if (newVal) {
					this.loadData(newVal);
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		loadData(id = "") {
			// console.log('code.index 监听到了嘛');
			this.$set(this, 'code_data', {})
			this.$forceUpdate()
			const addJosn = require(`./${id || this.id}.json`)
			//console.log(addJosn)
			// this.code_data = addJosn;
			this.$set(this, 'code_data', addJosn)
			return false

			// 此处必须清空，让整个code 重新执行
			// 原因数据传递引用太深，watch监听不到
			this.code_data = {}
			this.$get(this.$apis.code + '/' + (id || this.id)).then(res => {
				//console.log(res, 'resasdasdasdasdasdasd')
				//console.log(`the component is now created.2`);
				if (res.code == 200) {

					this.code_data = res.data;
				} else {
					this.common.message(this, res.message);
				}
				// this.tableloading = false;
			}).catch(err => {
				//console.log(err)
				this.common.message(this, err.message);
			});

		},
	},
};
</script>